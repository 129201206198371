import React from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Login from "./pages/Login"
import CardEditor from "./CardEditor"
import WebView from "./WebView"
import QuestionEditor from './QuestionEditor'
import { ManageCourses } from './ManageCourses'
import { UserContext } from './hooks/UserContext';
import useFindUser from './hooks/useFindUser';
import PrivateRoute from './pages/PrivateRoute';
import ResetPassword from "./pages/ResetPassword"
import ResetPasswordConfirmation from './pages/ResetPasswordConfirmation'
import RequestPasswordReset from './pages/RequestPasswordReset'

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

function App() {
  const {
    user,
    setUser, isLoading } = useFindUser();

  return (
    <Router>
      <UserContext.Provider value={{ user, setUser, isLoading }}>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/reset-password-confirmed" component={ResetPasswordConfirmation} />
          <Route exact path="/request-reset-password" component={RequestPasswordReset} />
          <PrivateRoute exact path="/" component={CardEditor} />
          <PrivateRoute exact path="/editor" component={CardEditor} />
          <PrivateRoute exact path="/view" component={WebView} />
          <PrivateRoute exact path="/manage-topics" component={ManageCourses} />
          <PrivateRoute exact path="/questions" component={QuestionEditor} />
        </Switch>
      </UserContext.Provider>
    </Router >
  )
}

export default App;
