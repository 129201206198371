import React, { useState, useEffect, useContext } from 'react'
import { Dropdown } from 'primereact/dropdown';
import axios from 'axios';
import { UserContext } from '../../hooks/UserContext';
import { ContentContext } from '../../hooks/ContentContext';
import qs from 'qs';


export default function SelectCourse({ searchQuery }) {
  const [selectOptions, setSelectOptions] = useState([]);
  const { user } = useContext(UserContext);
  const { selectedCourse, handleCourseChange } = useContext(ContentContext);

  useEffect(() => {
    getOptions();
  }, []);

  useEffect(() => {
    getOptions();
  }, [selectedCourse]);


  const getOptions = async () => {
    const res = await axios.get('/api/courses', {
      headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
    });

    const data = res.data.items

    const options = data.map(d => ({
      "value": d.id,
      "label": d.name
    }));

    setSelectOptions(options);

    if (searchQuery) {
      let courseID = qs.parse(searchQuery, { ignoreQueryPrefix: true })
      const result = options.find(({ value }) => value === parseInt(courseID.course));
      if (result) {
        handleCourseChange({ id: result.value, name: result.label })
      }
    }
  }

  const handleChange = (e) => {
    const result = selectOptions.find(({ value }) => value === e.value);
    if (result) {
      handleCourseChange({ id: result.value, name: result.label });
      console.log(`Selected course with id: ${e.value}`);
    }
  }

  return (
    <div className="selectbox">
      <Dropdown value={selectedCourse.id} options={selectOptions} onChange={handleChange} placeholder="Select a Course" optionLabel="label" filter />
    </div>
  )
}
