import React, { useState, useEffect, useContext, useRef } from "react";
import { ListBox } from "primereact/listbox";
import { ContextMenu } from 'primereact/contextmenu';
import { confirmDialog } from 'primereact/confirmdialog';
import axios from "axios";
import { UserContext } from "../hooks/UserContext";
import NewEvent from "./events/NewEvent";
import EditEvent from "./events/EditEvent";
import DeleteEvent from "./events/DeleteEvent";


const EventList = ({ onEventSelect }) => {
  const { user } = useContext(UserContext);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [events, setEvents] = useState([]);
  const [eventListItems, setEventListItems] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/events`, {
          headers: { Authorization: `Bearer ${user.token}`, "X-EDITOR": "nanoms" },
        });
        const eventsData = response.data;
        setEvents(eventsData);

        const eventListItems = eventsData.map((event) => ({
          label: `${event.date} - ${event.title}`,
          value: event.id,
        }));
        setEventListItems(eventListItems);
      } catch (error) {
        console.error(error);
      }
    };

    fetchEvents();
  }, [selectedEvent, user.token]);

  const handleSelection = async (event) => {
    const eventDetails = await getEvent(event.id);
    setSelectedEvent(eventDetails);
    onEventSelect(eventDetails);
  };

  const getEvent = async (eventId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/events/${eventId}`, {
        headers: { Authorization: `Bearer ${user.token}`, "X-EDITOR": "nanoms" },
      });
      const eventData = response.data;
      return eventData;
    } catch (error) {
      console.error(error);
    }
  };

  const addEventItem = (newEventListItem) => {
    const newList = [...eventListItems, newEventListItem];
    setEventListItems(newList);
  }

  const handleDelete = (deletedEventId) => { 
    const newList = eventListItems.filter(event => event.value !== deletedEventId);
    setEventListItems(newList);
  }

  return (
    <div>
      <div className="list-buttons">
        <NewEvent addEventItem={addEventItem} />
        <EditEvent selectedEvent={selectedEvent} />
        <DeleteEvent selectedEvent={selectedEvent} handleDelete={handleDelete} />
      </div>
      
      <ListBox
        value={selectedEvent ? selectedEvent.id : null}
        options={eventListItems}
        onChange={(e) => handleSelection({ id: e.value })}
        filter
        optionLabel="label"
        style={{ width: "15rem" }}
      />

      
    </div>
  );
};

export default EventList;
