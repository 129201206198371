import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/components/utils/ClassNames';
import "../css/LoginForm.css"
import 'primeflex/primeflex.css';
import axios from 'axios';


export default function RequestPasswordReset() {
  const [formData, setFormData] = useState({});
  const [showMessage, setShowMessage] = useState(false);
  const [showFailedMessage, setShowFaileMessage] = useState(false);

  useEffect(() => {
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validate: (data) => {
      let errors = {};

      if (!data.email) {
        errors.email = 'Email is required.';
      }
      else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
        errors.email = 'Invalid email address. E.g. example@email.com';
      }

      return errors;
    },
    onSubmit: (data) => {
      setFormData(data);
      requestPasswordReset(data).then(() => {
        setShowMessage(true);
      }).catch(() => {
        showFailedMessage(true);
      })
      formik.resetForm();
    }
  });

  async function requestPasswordReset(data) {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/reset_password_request`, { email: data.email });
    } catch (error) {
      console.error(error);
    }

  }

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  const dialogFooter = <div className="p-d-flex p-jc-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => setShowMessage(false)} /></div>;
  const failedDialogFooter = <div className="p-d-flex p-jc-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => setShowFaileMessage(false)} /></div>;


  return (
    <div className="login-form">
      <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
        <div className="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3">
          <h5>Password reset requested</h5>
          <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
            Please check <b>{formData.email}</b> for further instructions.
          </p>
        </div>
      </Dialog>

      <Dialog visible={showFailedMessage} onHide={() => setShowFaileMessage(false)} position="top" footer={failedDialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
        <div className="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3">
          <h5>Password reset failed</h5>
          <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
            We can't find user associated with <b>{formData.email}</b>. Please try another email.
          </p>
        </div>
      </Dialog>

      <div className="p-d-flex p-jc-center">
        <div className="prime-card">
          <h5 className="p-text-center">Reset password</h5>
          <form onSubmit={formik.handleSubmit} className="p-fluid">
            <div className="p-field">
              <span className="p-float-label p-input-icon-right">
                <i className="pi pi-envelope" />
                <InputText id="email" name="email" value={formik.values.email} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('email') })} />
                <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') })}>Email*</label>
              </span>
              {getFormErrorMessage('email')}
            </div>
            <Button type="submit" label="Request" className="p-mt-2" />
          </form>
        </div>
      </div>
    </div>
  );
}
