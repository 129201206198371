import React, { useRef, useState, useEffect } from 'react';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';

const ImageUploader = ({ uploadFolder }) => {
  const [folder, setFolder] = useState();
  const [uploadUrl, setUploadUrl] = useState();
  const toast = useRef(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setFolder(uploadFolder);
  }, [uploadFolder]);

  const onBeforeSend = (e) => {
    console.log("ON BEFORE SEND");
    console.log(folder);
    console.log(e);
    e.formData.append('folder', folder);
  }

  const onUpload = (event) => {
    const url = JSON.parse(event.xhr.response).location
    setUploadUrl(url);
    toast.current.show({ severity: 'info', summary: 'Success', detail: `File was uploaded` });
  }

  return (
    <div>
      <Toast ref={toast}></Toast>
      <div className="card editor-select">
        <h5> Upload image </h5>
        <div> {uploadUrl} </div>
        <FileUpload
          mode="basic"
          name="file"
          url={`${apiUrl}/upload`}
          accept="image/*"
          maxFileSize={5000000}
          onBeforeSend={onBeforeSend}
          onUpload={onUpload}
        />
      </div>
    </div>
  )
}

export default ImageUploader;
