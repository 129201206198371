import { Button } from 'primereact/button';
import { useContext } from "react"
import { confirmDialog } from 'primereact/confirmdialog';
import axios from "axios";
import { UserContext } from "../../hooks/UserContext";


export default function DeleteEvent({selectedEvent, handleDelete}) {
  const { user } = useContext(UserContext);

  const handleClick = () => {
    if (!selectedEvent) {
      return;
    }
    confirmDelete();
  }

  const confirmDelete = () => {
    confirmDialog({
      message: `Are you sure you want to delete even ${selectedEvent.title}?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Delete',
      rejectLabel: 'Cancel',
      accept: acceptDelete,
    });
  };

  const acceptDelete = () => {
    // delete event
    deleteEvent(selectedEvent.id);
    // delete event from list
    handleDelete(selectedEvent.id);
  };

  const deleteEvent = async (eventId) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/events/${eventId}`, {
        headers: { Authorization: `Bearer ${user.token}`, "X-EDITOR": "nanoms" },
      });
      const eventData = response.data;
      return eventData;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
     <Button label="Delete" className="p-button-rounded p-button-danger p-button-text" icon="pi pi-trash" onClick={handleClick} />
    </div>
  )
}
