import React, { useState, useEffect, useContext, useRef } from "react"
import axios from 'axios'
import { useFormik } from 'formik';

import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { ListBox } from 'primereact/listbox';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/components/utils/ClassNames';

import Header from './components/Header'
import { UserContext } from './hooks/UserContext';

import './css/QuestionEditor.css';

export default function QuestionEditor() {
  const toast = useRef(null);

  const [requestData, setRequestData] = useState([]);
  const [selectedQuestionID, setSelectedQuestionID] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);
  const [categories, setCategories] = useState([]);

  const { user } = useContext(UserContext);

  useEffect(() => {
    fetchQuestions();
    fetchCategories();
  }, []);

  // fetch questions after update
  useEffect(() => {
    fetchQuestions();
    setIsUpdated(false);
  }, [isUpdated]);

  // Get all questions from backend
  async function fetchQuestions() {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/questions`, {
      headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
    });
    setRequestData(res.data);
  }

  // Get all categories from backend
  async function fetchCategories() {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/courses/categories`, {
      headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
    });
    setCategories(res.data.items);
  }


  // Get labels for ListBox from request data
  const getQuestions = () => {
    const options = requestData.map(item => {
      return {
        label: item.question,
        value: item.id
      }
    });

    // Sort options by question id  
    options.sort((a, b) => {
      return a.value - b.value;
    });

    return options;
  };

  // Map categories to options for ListBox
  const getCategories = () => {
    const options = categories.map(item => {
      return {
        label: item.name,
        value: item.id
      }
    });

    return options;
  };

  // Get category icon_url from categories
  const getCategoryIcon = (categoryID) => {
    const category = categories.find(item => item.id === categoryID);
    if (category) {
      return category.icon_url;
    }
    return "";
  };

  // Get question itself by id from ListBox options
  const getLabelFromValue = (value) => {
    if (getQuestions()) {
      const item = getQuestions().find(item => item.value === value);
      if (item) {
        return item.label;
      }
    }
    return "";
  };

  // Get answers for specific question by id
  const getAnswersForQuestion = (question_id) => {
    var result = requestData.find(question => {
      return question.id === question_id
    })

    if (result) {
      return result.answers;
    } else {
      return [];
    }
  }

  // Logic for handling form
  const formik = useFormik({
    initialValues: {
      question: '',
      category: '',
      correctAnswer: '',
      answer1: '',
      answer2: '',
      answer3: '',
      answer4: '',
    },
    validate: (data) => {
      let errors = {};

      if (!data.question) {
        errors.name = 'Question can\'t be empty.';
      }

      if (!data.category) {
        errors.category = 'Category should be selected';
      }

      if (!data.answer1) {
        errors.answer1 = 'At least 1 answer is required';
      }

      if (!data.answer2 && data.answer3) {
        errors.answer2 = 'Answers can\'t be skipped';
      }

      if (!data.answer2 && !data.answer3 && data.answer4) {
        errors.answer2 = 'Answers can\'t be skipped';
      }

      if (!data.correctAnswer) {
        errors.correctAnswer = 'You need to choose one correct answer';
      }

      return errors;
    },
    onSubmit: (data) => {
      sendQuestion(data);
      toast.current.show({ severity: 'success', summary: 'Updated', detail: 'Question was successfully updated', life: 3000 });
      setIsUpdated(true);
    }
  });

  // Logic for handling ListBox change 
  const handleQuestionChange = (event) => {
    formik.resetForm({ errors: {} });
    formik.setFieldValue('question', getLabelFromValue(event.value));

    const question = requestData.find(question => { return question.id === event.value });
    if (question) {
      formik.setFieldValue('category', question.category);
    }

    setSelectedQuestionID(event.value);
    const answers = getAnswersForQuestion(event.value)
    if (answers.length > 0) {
      answers.forEach((answer, index) => {
        formik.setFieldValue('answer' + (index + 1), answer.answer);
        if (answer.correct) {
          formik.setFieldValue('correctAnswer', `a${index + 1}`);
        }
      });
    }
    else {
      resetAnswers();
    }
  };

  // Logic for handling category change
  const handleCategoryChange = (event) => {
    formik.setFieldValue('category', event.value);
  };

  // Reset answers values
  const resetAnswers = () => {
    formik.setFieldValue('answer1', '');
    formik.setFieldValue('answer2', '');
    formik.setFieldValue('answer3', '');
    formik.setFieldValue('answer4', '');
    formik.setFieldValue('correctAnswer', '');
  }


  const handleNewQuestion = () => {
    formik.resetForm();
    setSelectedQuestionID(null);
  }

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  // Send new or updated question to backend
  const sendQuestion = async (form_data) => {
    const initial_answers = getAnswersForQuestion(selectedQuestionID);

    const data = {
      question: form_data.question,
      category: form_data.category,
      answers: [
        {
          answer: form_data.answer1,
          id: initial_answers.length > 0 ? initial_answers[0].id : null,
          correct: form_data.correctAnswer === 'a1'
        },
        {
          answer: form_data.answer2,
          id: initial_answers.length > 1 ? initial_answers[1].id : null,
          correct: form_data.correctAnswer === 'a2'
        },
        {
          answer: form_data.answer3,
          id: initial_answers.length > 2 ? initial_answers[2].id : null,
          correct: form_data.correctAnswer === 'a3'
        },
        {
          answer: form_data.answer4,
          id: initial_answers.length > 3 ? initial_answers[3].id : null,
          correct: form_data.correctAnswer === 'a4'
        }
      ]
    }

    if (selectedQuestionID) {
      await axios.put(`${process.env.REACT_APP_API_URL}/questions/${selectedQuestionID}`, data, {
        headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
      });
    } else {
      await axios.post(`${process.env.REACT_APP_API_URL}/questions`, data, {
        headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
      });
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      <Header />
      <div className="title">
        <h2> Nerdish Questions</h2>
      </div>
      <div className="question-editor">
        <div className="questions sidebar">
          <ListBox value={formik.values.question} options={getQuestions()} onChange={handleQuestionChange} filter
            style={{ width: '15rem' }} />
        </div>
        <div className="questions content">
          <Button label="New Question" className="p-button-outlined" icon="pi pi-plus-circle" iconPos="left" onClick={handleNewQuestion} />
          <form onSubmit={formik.handleSubmit}>
            <InputTextarea id="question" value={formik.values.question} rows={5} cols={30} autoResize onChange={formik.handleChange} />
            {getFormErrorMessage('question')}
            <h5>Category</h5>
            <Dropdown value={formik.values.category} options={getCategories()} onChange={handleCategoryChange} placeholder="Select a Category" style={{ width: '15rem' }} />
            <img className="category-icon" src={getCategoryIcon(formik.values.category)} alt={formik.values.category} />
            {getFormErrorMessage('category')}
            <hr />
            {getFormErrorMessage('correctAnswer')}
            <div className="p-inputgroup">
              <InputText placeholder="Answer 1" id="answer1" name="answer1" value={formik.values.answer1} onChange={formik.handleChange}
                autoFocus className={classNames({ 'p-invalid': isFormFieldValid('answer1') })} />
              <span className="p-inputgroup-addon">
                <RadioButton name="correctAnswer" value="a1" checked={formik.values.correctAnswer === "a1"} onChange={formik.handleChange} />
              </span>
            </div>
            {getFormErrorMessage('answer1')}

            <div className="p-inputgroup">
              <InputText placeholder="Answer 2" id="answer2" name="answer2" value={formik.values.answer2} onChange={formik.handleChange}
                autoFocus className={classNames({ 'p-invalid': isFormFieldValid('answer2') })} />
              <span className="p-inputgroup-addon">
                <RadioButton name="correctAnswer" value="a2" checked={formik.values.correctAnswer === "a2"} onChange={formik.handleChange} />
              </span>
            </div>
            {getFormErrorMessage('answer2')}

            <div className="p-inputgroup">
              <InputText placeholder="Answer 3" id="answer3" name="answer3" value={formik.values.answer3} onChange={formik.handleChange}
                autoFocus className={classNames({ 'p-invalid': isFormFieldValid('answer3') })} />
              <span className="p-inputgroup-addon">
                <RadioButton name="correctAnswer" value="a3" checked={formik.values.correctAnswer === "a3"} onChange={formik.handleChange} />
              </span>
            </div>
            {getFormErrorMessage('answer3')}

            <div className="p-inputgroup">
              <InputText placeholder="Answer 4" id="answer4" name="answer4" value={formik.values.answer4} onChange={formik.handleChange}
                autoFocus className={classNames({ 'p-invalid': isFormFieldValid('answer4') })} />
              <span className="p-inputgroup-addon">
                <RadioButton name="correctAnswer" value="a4" checked={formik.values.correctAnswer === "a4"} onChange={formik.handleChange} />
              </span>
            </div>
            {getFormErrorMessage('answer4')}

            <Button type="submit" label="Submit" className="p-mt-2" />
          </form>

        </div>
      </div>

    </div>
  )

}






