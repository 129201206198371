import React, { useState } from 'react';
import '../css/LoginForm.css'
import useAuth from '../hooks/useAuth';
import 'primeflex/primeflex.css';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export default function Login() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const { loginUser, error } = useAuth();
  let history = useHistory();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/" } };

  useEffect(() => {
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async e => {
    e.preventDefault();
    await loginUser({
      email,
      password
    });
    history.push(from);
  }

  return (
    <div className="login-form">
      <div className="p-d-flex p-jc-center">
        <div className="prime-card">
          <h4 className="p-text-center">Factorium login</h4>
          <form onSubmit={handleSubmit} className="p-fluid">
            <div className="p-field">
              <label htmlFor="email">Email</label>
              <span className="p-input-icon-right">
                <i className="pi pi-envelope" />
                <InputText id="email" name="email" onChange={e => setEmail(e.target.value)} />
              </span>
            </div>
            <div className="p-field">
              <label htmlFor="password">Password</label>
              <Password id="password" name="password" toggleMask feedback={false} onChange={e => setPassword(e.target.value)} />
            </div>
            <div>
              <Button type="submit" label="Login" className="p-mt-2" />
            </div>
          </form>
          <Divider />
          <div className="p-text-right"><a href="/request-reset-password">Forgot Password?</a></div>
        </div>
      </div>
    </div>
  )
}
