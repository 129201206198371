import React, { useState, useEffect, useContext, useRef } from "react"

import { Button } from "react-bootstrap"
import { InputNumber } from 'primereact/inputnumber';
import { Toast } from 'primereact/toast';

import CourseTree from "./components/tree/CourseTree"
import SelectCourse from "./components/course/SelectCourse"
import EditCourse from "./components/course/EditCourse"
import NewCourse from "./components/course/NewCourse"
import LinkedCourses from "./components/course/LinkedCourses"
import EventList from "./components/EventList"

import ImageUploader from "./ImageUploader"
import EventPreview from './EventPreview'
import axios from 'axios'
import { UserContext } from './hooks/UserContext';
import { ContentContext } from "./hooks/ContentContext";

import 'tinymce/tinymce'
import 'tinymce/icons/default'
import 'tinymce/themes/silver'
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/skins/ui/oxide/content.min.css'
import 'tinymce/skins/content/default/content.min.css'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/image'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/nonbreaking'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/code'
import { Editor } from "@tinymce/tinymce-react"
import Header from './components/Header'

import './css/CardEditor.css';

export default function EventEditor({ location, props }) {
  const [content, setContent] = useState("");
  const [selectedDate, setSelectedDate] = useState();
  const [selectedEvent, setSelectedEvent] = useState();

  const { user } = useContext(UserContext);
  const toast = useRef(null);

  useEffect(() => {
    const getContent = async () => {

      if (selectedEvent) { // need to have event id before request
        const event = await getEvent(selectedEvent.id);
        setContent(event.body);
      }
    }
    getContent();
  }, [selectedEvent]);

  useEffect(() => setContent(content), [content]);


  const handleEditorChange = (content, editor) => {
    setContent(content)
  }

  const getEvent = async (eventId) => {
    let data = null;
  
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/events/${eventId}`, {
        headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
      });
      data = res.data;
    } catch (error) {
      console.error(error);
    }
    
    return data;
  }
  

  const filterContent = (content) => {
    const parser = new DOMParser();

    // Filter out text without <p>
    // <div>Some text</div> will not be saved
    const htmlDoc = parser.parseFromString(content, 'text/html');
    const divs = htmlDoc.querySelectorAll("div");
    for (const div of divs) {
      if (!div.hasChildNodes()) {
        console.log("Removing div without child elements")
        div.remove();
      }
      for (const child of div.childNodes) {
        if (child.nodeType === 3) {// 3 is TEXT_NODE type 
          console.log("Removing text child of div element")
          div.removeChild(child);
        }
      }
    }

    return htmlDoc.body.innerHTML;
  }

  const updateContent = async (eventContent) => {
    let data = [];
    let res;

    try {
      res = await axios.put(`${process.env.REACT_APP_API_URL}/events/${selectedEvent.id}`, {
        body: filterContent(eventContent)
      }, {
        headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
      });
      data = res.data;
      console.log(data);
    } catch (error) {
      console.error(error);
    }
    return res;
  }

  const handlePublishClick = async (event) => {
    console.log(event)
    const response = await updateContent(content);
    if (response && response.status === 200) {
      toast.current.show({ severity: 'success', summary: 'Event updated', detail: 'Event was properly saved', life: 3000 });
    } else {
      toast.current.show({ severity: 'warn', summary: 'Possible problem', detail: 'Event might not be properly saved. Please check content.', life: 5000 });
    }
  }

  const handleEventChange = async (currentEvent) => {
    if (!currentEvent) return;
    console.log(`Our event to update is ${currentEvent.id}`)
    setSelectedEvent(currentEvent);
  };

  const noteBlockDialog = (editor, target = null) => {
    return editor.windowManager.open({
      type: 'panel',
      title: 'Note Block Details',
      size: 'large',
      body: {
        type: 'panel',
        items: [
          {
            type: 'selectbox', // component type
            name: 'template', // identifier
            label: 'Choose Template',
            size: 1, // number of visible values (optional)
            items: [
              { value: 'fact', text: 'Fun Fact' },
              { value: 'focus', text: 'Stay Focused' },
              { value: 'summary', text: 'Summary' },
              { value: 'didyouknow', text: 'Did you know?' },
              { value: 'nerdishness', text: 'A bit of Nerdish-ness' }
            ]
          },
          {
            type: 'textarea',
            name: 'text',
            label: 'Text',
            maximized: true
          },

        ]
      },
      buttons: [
        {
          type: 'cancel',
          text: 'Close'
        },
        {
          type: 'submit',
          text: 'Add',
          primary: true
        }
      ],
      initialData: {
        template: target ? target.getAttribute('template') : "",
      },
      onSubmit: function (api) {
        var data = api.getData();
        // Insert content when the window form is submitted
        if (target) {
          target.setAttribute('question', data.template)
          target.setAttribute('text', data.text)
        }
        else {
          var templates = [
            { title: 'Fun Fact', image: 'https://nerdish-images.s3.eu-central-1.amazonaws.com/button-card-images/fun_fact.png' },
            { title: 'Stay Focused', image: 'https://nerdish-images.s3.eu-central-1.amazonaws.com/button-card-images/stay_focused.png' },
            { title: 'Summary', image: 'https://nerdish-images.s3.eu-central-1.amazonaws.com/button-card-images/summary.png' },
            { title: 'Did you know?', image: 'https://nerdish-images.s3.eu-central-1.amazonaws.com/button-card-images/summary.png' },
            { title: 'A bit of Nerdish-ness', image: 'https://nerdish-images.s3.eu-central-1.amazonaws.com/button-card-images/summary.png' },
          ];

          var selected_template;
          switch (data.template) {
            case 'fact':
              selected_template = templates[0];
              break;
            case 'focus':
              selected_template = templates[1];
              break;
            case 'summary':
              selected_template = templates[2];
              break;
            case 'didyouknow':
              selected_template = templates[3];
              break;
            case 'nerdishness':
              selected_template = templates[4];
              break;
            default:
              console.log(`Sorry, there is no ${data.template} template.`);
          }

          editor.insertContent('<note img="' + selected_template.image + '" ' +
            'title="' + selected_template.title + '">' +
            data.text +
            '</note>');

        }
        api.close();
      }
    });
  };



  return (
    <div className="App">
      <Header />
      <Toast ref={toast} />
      <div className="container-fluid">
        <div className="row mt-4">
          <div className="col-2">
          </div>
          <div className="col-6 text-center">
            <h5>
              <div className="text-align-center">
                Factorium
              </div>
            </h5>
            <h3>{selectedEvent?.title || 'No Event Selected'}</h3>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-2 course-tree">
            {/* Pass the callback function handleEventSelection */}
            <EventList onEventSelect={handleEventChange} />
          </div>
          <div className="col-6">
            <div className="top-group">
              <div>

              </div>
            </div>
            <div className="mark-input text-center" style={{ zIndex: -1 }}>
              <Editor
                // apiKey="j6sj33xh2w17dxehinfd4s24wjx59z5j7eke94sibxout2nq"
                // initialValue="<p>This is the initial content of the editor</p>"
                value={content}
                init={{
                  menubar: 'file edit insert view tools ',
                  menu: {
                    custom: { title: 'Media', items: 'link myCustomMenuItem' }
                  },
                  skin: false,
                  setup: function (editor) {

                    editor.ui.registry.addButton('noteCard', {
                      text: 'Note',
                      onAction: function () {
                        // Open window
                        noteBlockDialog(editor);
                      }
                    });


                    return {
                      getMetadata: function () {
                        return {
                          name: 'Nerdish plugin',
                          url: 'http://nerdish.io'
                        };
                      }
                    };
                  },
                  height: 500,
                  plugins: [
                    'image', 'link', 'lists', 'paste',
                    'visualblocks', 'wordcount', 'code',
                    'nonbreaking', 'charmap'
                  ],
                  toolbar:
                    'undo redo | styleselect | quiz noteCard courseCard | bold italic | h1 h2 h3 | bullist numlist | superscript subscript | nonbreaking charmap | image |  code | help',
                  image_caption: true,
                  contextmenu: false,
                  font_formats:
                    "Quicksand=quicksand",
                  content_style:
                    "@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap'); body { font-family: Noto Sans; font-size: 14pt;}",
                  style_formats: [
                    {
                      title: 'Blocks', items: [
                        { title: 'text', block: 'p', wrapper: false, merge_siblings: false },
                      ]
                    }
                  ],
                  visualblocks_default_state: true,
                  end_container_on_empty_block: true,
                  forced_root_block: 'div',
                  valid_elements: 'p,h1,h2,h3,img[src|alt],figure,figcaption,div,ol,ul,li,strong,em,a[href],sup,sub',
                  valid_children: '+p[strong|em|a|#text|sup|sub],-p[img|note|course],-div[#text|ol|ul|figure|p|strong|em],+ul[li],+ol[li],-strong[img],a[#text]',
                  extended_valid_elements: "quiz[title|question|answer1|answer2|answer3|correct_answer],note[img|title],course[id|title|actiontext|formaction|postactiontext],button[type|formaction|#text]",
                  custom_elements: "~quiz,~note,~course",
                  content_css: "editor.css",
                  entity_encoding: 'raw'
                }}
                onEditorChange={handleEditorChange}
              />
              {selectedEvent && (
                <ImageUploader
                  uploadFolder={`event_images/${selectedEvent.date}/${selectedEvent.id}`}
                  />
                )}
              <div className="publish-button">
                {/* Do not show button if there is no card seleted */}
                {selectedEvent &&
                  <Button size="lg" block onClick={handlePublishClick}>Save</Button>
                }
              </div>
            </div>
          </div>
          <div className="col">
            <EventPreview content={content}
              selectedEvent={selectedEvent} />
          </div>
        </div>
      </div>
    </div >
  )
}






