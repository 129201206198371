import React, { useState, useEffect, useContext } from "react"

import { InputNumber } from 'primereact/inputnumber';

import CourseTree from "./components/tree/CourseTree"
import SelectCourse from "./components/course/SelectCourse"

import EventPreview from './EventPreview'
import Logout from "./pages/Logout"
import axios from 'axios'
import { UserContext } from './hooks/UserContext';
import { ContentContext } from "./hooks/ContentContext";

import './css/WebView.css';

export default function WebView({ props }) {
  const [content, setContent] = useState("");
  const [selectedCourse, setSelectedCourse] = useState({});
  const [selectedTopic, setSelectedTopic] = useState({});
  const [selectedCard, setSelectedCard] = useState();

  const { user } = useContext(UserContext);

  useEffect(() => {
    const getContent = async () => {
      // when selected node in tree is Topic
      if (!selectedCard && selectedTopic) {
        setContent("This is a Topic")
      };

      if (selectedCard) { // need to have card id before request
        const card = await getCard(selectedCard.id);
        setContent(card.body);
      }
    }
    getContent();
  }, [selectedCard]);

  useEffect(() => setContent(content), [content]);


  const getCard = async (cardId) => {
    let data = [];

    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/cards/${cardId}`, {
        headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
      });
      data = res.data;
    } catch (error) {
      console.error(error);
    }
    return data

  }

  const getTopic = async (topicId) => {
    let data = [];

    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/topics/${topicId}`, {
        headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
      });
      data = res.data;
    } catch (error) {
      console.error(error);
    }
    return data

  }

  const filterContent = (content) => {
    const parser = new DOMParser();

    // Filter out text without <p>
    // <div>Some text</div> will not be saved
    const htmlDoc = parser.parseFromString(content, 'text/html');
    const divs = htmlDoc.querySelectorAll("div");
    for (const div of divs) {
      if (!div.hasChildNodes()) {
        console.log("Removing div without child elements")
        div.remove();
      }
      for (const child of div.childNodes) {
        if (child.nodeType === 3) {// 3 is TEXT_NODE type 
          console.log("Removing text child of div element")
          div.removeChild(child);
        }
      }
    }

    return htmlDoc.body.innerHTML;
  }

  const updateContent = async (cardContent) => {
    let data = [];

    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/cards/${selectedCard.id}`, {
        body: filterContent(cardContent),
        position_id: selectedCard.position_id
      }, {
        headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
      });
      data = res.data;
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  }

  const handlePositionDisplay = () => {
    console.log('Handling Position:')
    console.log(selectedCard['position_id'])
    return selectedCard['position_id'];
  }


  const handleCourseChange = (currentCourse) => {
    setSelectedCourse(currentCourse);
    setSelectedTopic({});
  };

  const handleTopicChange = async (currentTopicId) => {
    console.log(`Our topic to update is ${currentTopicId}`)
    const currentTopic = await getTopic(currentTopicId);
    setSelectedTopic(currentTopic);
    setSelectedCard(null);
    console.log(`We changed topic and selected topic is ${currentTopic.name}`)
  };

  const handleCardChange = async (currentCardId, currentTopicId) => {
    console.log(`Our card to update is ${currentCardId}`)
    const card = await getCard(currentCardId);
    const topic = await getTopic(currentTopicId);
    setSelectedCard(card);
    setSelectedTopic(topic);
  };

  return (
    <div className="App">
      <ContentContext.Provider value={{ selectedCourse, handleCourseChange }}>
        <div className="WebView">
          <div className="title">
            <h2> Nerdish Viewer</h2>
            <div className="left-title">
              <Logout />
            </div>
          </div>
          <div className="active-area">
            <div className="course-tree">
              <div className="course-elements">
                <SelectCourse
                  onCourseSelect={handleCourseChange}
                  selectedCourse={selectedCourse}
                />
              </div>
              <CourseTree
                selectedCourse={selectedCourse}
                selectedTopic={selectedTopic}
                selectedCard={selectedCard}
                onTopicSelect={handleTopicChange}
                onCardSelect={handleCardChange}
                canEdit={false}
              />
            </div>
            <div className="preview">
              <EventPreview content={content}
                selectedCourse={selectedCourse}
                selectedTopic={selectedTopic} />
            </div>
          </div>
        </div>
      </ContentContext.Provider>
    </div>
  )
}






