import axios from 'axios'
import { useState, useContext } from "react"
import { UserContext } from '../../hooks/UserContext';
import SelectEventCategory from './SelectEventCategory';

import { Form, Modal } from "react-bootstrap"
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';

export default function EditEvent({selectedEvent}) {
  const [show, setShow] = useState(false);
  const { user } = useContext(UserContext);

  const apiUrl = process.env.REACT_APP_API_URL;

  // Form data
  const [date, setDate] = useState();
  const [categories, setCategories] = useState([]);
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [body, setBody] = useState(); 
  const [image, setImage] = useState();
  
  const handleClose = () => {
    setShow(false);
  }
  const handleShow = async () => {
    if (selectedEvent) {
      await getEvent();
    }
    setShow(true);
  }

  const getEvent = async () => {
    let url = `${process.env.REACT_APP_API_URL}/events/${selectedEvent.id}`;

    try {
      const response = await axios.get(url, {
        headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' },
      });
      const result = response.data;
      setDate(result.date);
      setCategories(result.categories);
      setTitle(result.title);
      setDescription(result.description);
      setBody(result.body);
      setImage(result.image);
    } catch (error) {
      console.error(error);
    }
  }


  const editEvent = async () => {
    let url = `${process.env.REACT_APP_API_URL}/events/${selectedEvent.id}`;
  
    try {
      const response = await axios.put(url, {
        date: date,
        categories: categories.map(cat => cat.value),
        title: title,
        description: description,
        body: body,
        image: image,
      }, {
        headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' },
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  const handleSubmit = async e => {
    e.preventDefault();
    setShow(false);
    console.log("The event is going to be edited here")
    const result = await editEvent();
    const eventItem = {
      label: `${result.date} - ${result.title}`,
      value: result.id,
    }
    console.log(`Event edit request got following response: `)
    console.log(result)
  }

  const handleCategoryChange = (currentCategory) => {
    setCategories(currentCategory);
    console.log(`We changed category and selected category is ${currentCategory}`)
    console.log(currentCategory)
  };

  const onBeforeSend = (e) => {
    e.formData.append('folder', `event_images/${date}`);
  }

  const onUpload = (event) => {
    const url = JSON.parse(event.xhr.response).location
    setImage(url);
  }


  return (
    <>
      <Button label="Edit" onClick={handleShow} icon="pi pi-pencil" className="p-button-rounded p-button-text" />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formEventDate">
            <Form.Label>Date (YYYY-MM-DD, e.g. 1981-09-30): </Form.Label>
            <Form.Control type="text" onChange={e => setDate(e.target.value)} value={date} placeholder="" />
          </Form.Group>
          <Form.Group controlId="formEventCategory">
            <Form.Label>Category: </Form.Label>
            {/* <Form.Control type="text" onChange={e => handleCategoryChange(e.target.value)} value={categories} placeholder="" /> */}
            <SelectEventCategory
              selectedEvent={selectedEvent}
              onCategorySelect={handleCategoryChange}
            />
          </Form.Group>
          <Form.Group controlId="formEventTitle">
            <Form.Label>Title: </Form.Label>
            <Form.Control type="text" onChange={e => setTitle(e.target.value)} value={title} placeholder="" />
          </Form.Group>
          <Form.Group controlId="formEventDescription">
            <Form.Label>Description: </Form.Label>
            <Form.Control type="text" as='textarea' onChange={e => setDescription(e.target.value)} value={description} placeholder="" />
          </Form.Group>
          <Form.Group controlId="formUploadImage">
            <Form.Label>Image: </Form.Label>
            <Form.Control type="text" value={image} placeholder="" readOnly />
            <FileUpload
              mode="basic"
              name="file"
              url={`${apiUrl}/upload`}
              accept="image/*"
              maxFileSize={5000000}
              onBeforeSend={onBeforeSend}
              onUpload={onUpload}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
