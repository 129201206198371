import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/components/utils/ClassNames';
import '../css/LoginForm.css';
import 'primeflex/primeflex.css';
import useQuery from '../hooks/useQuery';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

export default function ResetPassword() {
  const [formData, setFormData] = useState({});
  const [validUser, setValidUser] = useState(false);
  const [secretToken, setSecretToken] = useState();

  let query = useQuery();
  let history = useHistory();

  useEffect(() => {
    async function validateSecret() {
      const secret = query.get("secret");
      setSecretToken(secret);
      if (secret) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/verify_reset?secret=${secret}`);
          setValidUser(response.data);
        } catch (error) {
          console.error(error);
        }
      }
    }
    validateSecret();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validate: (data) => {
      let errors = {};

      if (!data.password) {
        errors.password = 'Password is required.';
      }

      if (!data.confirmPassword) {
        errors.confirmPassword = 'You need to confirm password.';
      } else if (data.confirmPassword != data.password) {
        errors.confirmPassword = 'Passwords do not match'
      }

      return errors;
    },
    onSubmit: (data) => {
      setFormData(data);
      updatePassword(data).then(() => {
        history.push('/reset-password-confirmed');
      });
      formik.resetForm();
    }
  });

  async function updatePassword(data) {
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/users/${validUser['id']}/reset_password`, { password: data.password, confirm_password: data.confirmPassword, secret: secretToken });
      setValidUser(response.data);
    } catch (error) {
      console.error(error);
    }

  }

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  const passwordHeader = <h6>Pick a password</h6>;
  const passwordFooter = (
    <React.Fragment>
      <Divider />
      <p className="p-mt-2">Suggestions</p>
      <ul className="p-pl-2 p-ml-2 p-mt-0" style={{ lineHeight: '1.5' }}>
        <li>At least one uppercase</li>
        <li>At least one numeric</li>
        <li>Minimum 8 characters</li>
      </ul>
    </React.Fragment>
  );

  return (
    <div className="form">
      {validUser ?
        <div className="p-d-flex p-jc-center">
          <div className="prime-card">
            <h5 className="p-text-center">Reset Password</h5>
            <form onSubmit={formik.handleSubmit} className="p-fluid">
              <div className="p-field">
                <span className="p-float-label">
                  <Password id="password" name="password" value={formik.values.password} onChange={formik.handleChange} toggleMask
                    className={classNames({ 'p-invalid': isFormFieldValid('password') })} header={passwordHeader} footer={passwordFooter} />
                  <label htmlFor="password" className={classNames({ 'p-error': isFormFieldValid('password') })}>Password*</label>
                </span>
                {getFormErrorMessage('password')}
              </div>
              <div className="p-field">
                <span className="p-float-label">
                  <Password id="confirmPassword" name="confirmPassword" value={formik.values.confirmPassword} onChange={formik.handleChange} toggleMask feedback={false}
                    className={classNames({ 'p-invalid': isFormFieldValid('confirmPassword') })} />
                  <label htmlFor="confirmPassword" className={classNames({ 'p-error': isFormFieldValid('confirmPassword') })}>Confirm password*</label>
                </span>
                {getFormErrorMessage('confirmPassword')}
              </div>

              <Button type="submit" label="Submit" className="p-mt-2" />
            </form>
          </div>
        </div>
        :
        <div className="p-d-flex p-jc-center">
          <div className="prime-card">
            <h5 className="p-text-center">Can't reset password</h5>
            <p>The password reset link is not valid. Please check your email for the correct link or contact <a href="mailto: we@nerdish.io">we@nerdish.io</a>.</p>
            <div>Nerdish Team</div>
          </div>
        </div>
      }

    </div>
  );
}
